import { useEffect, ChangeEvent, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import styled from "styled-components";
import { useQueryClient } from "react-query";
import useMutationCustom from "hooks/useMutationCustom";
import useQueryCustom from "hooks/useQueryCustom";
import isEqual from 'date-fns/isEqual';
import isWithinInterval from "date-fns/isWithinInterval";
import { useToasts } from "react-toast-notifications";
import {getWorkingDays, getTimeOffRequestTypes} from "services";
import { NumericFormat } from 'react-number-format';
import DialogModal, { IDialogProps } from "components/Modal/Dialog";
import SelectDropdown from "components/Dropdowns/SelectDropdown";
import Checkbox from "components/Checkbox";
import DatePicker from "components/DatePickers/DatePicker";
import UniversalInput from "components/Input/UniversalInput";
import TextArea from "components/TextArea";
import { usePermissionGate } from "permissions/usePermissionGate";
import { useTranslation } from "react-i18next";
import EmployeeInfoHeader from 'containers/Employee/editHeader';
import type { IEmployeeMainInfo } from './.';
import { isEmpty } from "lodash";
import { formatNumber } from "utils/common";
import TimeOffBalance from "components/TimeOffBalance";
import MainErrorBox from 'components/error/mainError';
import { useSelector } from "react-redux";
import { currentUserSelector } from "redux/selectors";
interface IRequestTimeOffModal extends IDialogProps {
    employeeInfo: IEmployeeMainInfo
};

type TFormValues = {
    automatic_approval: boolean,
    time_off_type: {
        id: number,
        name: string,
        deleted: boolean
    } | null,
    date_from: Date | string,
    date_to: Date | string,
    requested_hours: number | null,
    requested_days: number | null,
    time_off_period: string,
    note: string
};

type TCalendarRange = {
    id?: number,
    time_off_day: Date,
    time_off_hour: string
};

type TCreateTimeOffArgs = {
    automatic_approval: boolean,
    employee_id: number,
    time_off_type_id: number | undefined,
    time_off_period: string,
    requested_hours: number | null,
    date_from: string | Date,
    date_to: string | Date,
    note: string,
    time_off_hours_attributes?: TCalendarRange[]
};

type TRequestError = {
    errors: [{
        type: string,
        field: string,
        message: string
    }]
};

type TTimeOffUsedData = {
    hours_requested: string,
    hours_used: string,
    hours_scheduled: string,
};

type TServerErrors = {
    overlaps: string,
    duplication: string
};

export default function RequestTimeOffModal({ employeeInfo, open, timeOffType, onClose, onRefresh, ...rest }: any) {
    const { addToast } = useToasts();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { role } = usePermissionGate();
    const [intervalWithHourData, setIntervalWithHourData] = useState<TCalendarRange[]>([]);
    const [balance, setBalance] = useState<any>(null);
    const emptyErrors: TServerErrors = { overlaps: '', duplication: '' };
    const [serverErrors] = useState<TServerErrors>(emptyErrors);
    const { control, setValue, watch, reset, handleSubmit, clearErrors, setError, formState: { errors } } = useForm<TFormValues>({
        defaultValues: {
            automatic_approval: false,
            time_off_type: null,
            date_from: '',
            date_to: '',
            requested_hours: null,
            requested_days: null,
            time_off_period: 'full',
            note: '',
        }
    });
    const watchFromDate = watch('date_from');
    const watchToDate = watch('date_to', '');
    const watchTimeOffType = watch('time_off_type');
    const watchRequestedDays = watch('requested_days');
    const watchRequestedHours = watch('requested_hours');
    const watchTimeOffPeriod = watch('time_off_period');
    const currentUser = useSelector(currentUserSelector)

    useEffect(() => {
        if (!isEmpty(timeOffType)) {
            setValue('time_off_type', timeOffType)
        }
    }, [timeOffType])

    const onError = (err: any) => {
        if (err) {
            addToast(<ToastContentContainer dangerouslySetInnerHTML={{ __html: t('globaly.fix_Highlighted') }} />, {
                appearance: 'error',
                autoDismiss: true,
                placement: 'top-center'
            });
        }
    };

    const { mutate: createTimeOff, isLoading: createTimeOffLoading } = useMutationCustom<string[], TRequestError, TCreateTimeOffArgs>(["post_time_off_request"], {
        endpoint: 'time_off/time_off_request', options: { method: "post" },
    }, {
        onSuccess: (_, variables) => {
            clearServerErrors();
            addToast(
                variables.automatic_approval ? t('timeOff.You_have_successfully_added') : t('timeOff.you_have_successfully_requested_time_fff'),
                { appearance: 'success', autoDismiss: true }
            );
            onClose?.({}, 'escapeKeyDown');
            reset();
            setBalance(null);
            queryClient.invalidateQueries('time_off_requests_list');
            setIntervalWithHourData([]);
            onRefresh();
        },
        onError: (err) => {
            clearServerErrors();
            err.errors.forEach((item) => {
                if (['overlaps', 'duplication'].includes(item.type)) {
                    addToast(<ToastContentContainer dangerouslySetInnerHTML={{ __html: t('globaly.fix_Highlighted') }} />, {
                        appearance: 'error',
                        autoDismiss: true,
                        placement: 'top-center'
                    });
                    Object.assign(serverErrors, { [item.type]: item.message });
                } else {
                    addToast(item.message, { appearance: 'error', autoDismiss: true });
                }
            });
        }
    });

    useEffect(() => {
        if (watchFromDate) {
            holidayList(watchFromDate, watchToDate);
        } else {
            setValue('requested_hours', 0);
        }
        clearServerErrors();
    }, [setValue, watchFromDate, watchToDate, watchTimeOffType]);

    useEffect(() => {
        setValue('requested_days', formatNumber(Number(watchRequestedHours)/8));
        
    }, [watchRequestedHours]);

    const dateValidator = (value: Date | string, type: string) => {
        if (type === 'date_from') {
            if (value > watchToDate as unknown) { 
                return t('timeOff.validation_date_from');
            }
        };

        if (type === 'date_to') {
            if (value < watchFromDate as unknown) {
                return t('timeOff.validation_date_from')
            }
        };

        return value === null ? t('validations.valid_date') : value !== '' || t('validations.is_required', {attribute: t('timeOff.date')})
    };

    useEffect(() => {
        if (watchToDate >= watchFromDate) {
            clearErrors('date_from')
        }
    }, [watchToDate, watchFromDate]);

    const handleFormSubmit = (data: TFormValues) => {
        let formHoursAttributes = intervalWithHourData.filter(e => isWithinInterval(new Date(e.time_off_day), { start: watchFromDate as Date, end: watchToDate as Date }));

        let formData = {
            employee_id: employeeInfo.id,
            automatic_approval: data.automatic_approval,
            time_off_type_id: data.time_off_type?.id,
            date_from: data.date_from,
            date_to: data.date_to,
            requested_hours: data.requested_hours || null,
            time_off_period: data.time_off_period,
            note: data.note,
            time_off_hours_attributes: formHoursAttributes
        };
        createTimeOff(formData);
    };

    const holidayList = (from: any, to: any) => {
        getWorkingDays(from.toDateString(), to ? to.toDateString() : null, 8, employeeInfo, watchTimeOffType).then(res => {
            setBalance(res.data.hours);
            setValue('requested_hours', Number(res.data.requested_hours))
        })
    };

    const handlePartialCalendarChangeRange = (range: TCalendarRange[]) => {
        setIntervalWithHourData(range);
    };

    const clearServerErrors = () => {
        serverErrors.overlaps = '';
        serverErrors.duplication = '';
    };

    const handlePartialCalendarChangeCell = (cell: TCalendarRange) => {
        let computedRange = intervalWithHourData.map(e => {
            if (isEqual(cell.time_off_day, e.time_off_day)) {
                return cell;
            } else return e;
        });
        setIntervalWithHourData(computedRange);
    };

    const onCloseModal = () => {
        setBalance(null)
        reset();
        onClose();
    }

    return (
        <DialogModal
            open={open}
            onClose={onCloseModal}
            withButtons
            title={t('leftMenuCard.request_time_off')}
            actionButtonText={t('timeOff.send_request')}
            cancelButtonText={t('globaly.cancel')}
            actionButton={handleSubmit(handleFormSubmit, onError)}
            actionLoading={createTimeOffLoading}
            nominalHeader={
                <EmployeeInfoHeader
                    employeeName={`${employeeInfo.first_name} ${employeeInfo.last_name}`}
                    avatarUuid={employeeInfo.uuid}
                    employeeId={employeeInfo.id}
                    jobData={employeeInfo.active_job_detail}
                />}
            maxWidth={'sm'}
            fullWidth
            {...rest}
        >
            <DialogContainer>
                <FieldsContainer>
                    <div>
                        <Controller
                            name="time_off_type"
                            control={control}
                            rules={{ required: t('validations.is_required', {attribute: t('timeOff.time_off_type')}) }}
                            render={({ field: { onChange, value } }) => (
                                <SelectDropdown
                                    inputPlaceholder={t('globaly.select', {title: t('timeOff.time_off_type')})}
                                    onChange={(_event: React.SyntheticEvent<Element, Event>, newValue: unknown) => {
                                        onChange(newValue)
                                    }}
                                    value={value}
                                    loadRemoteData={() => getTimeOffRequestTypes(100, 1)}
                                    errorText={errors.time_off_type?.message || serverErrors.duplication}
                                    label={t('timeOff.time_off_type')}
                                    required
                                />
                            )}
                        />
                    </div>
                    {!!serverErrors.duplication && <MainErrorBox type='error' text={serverErrors.duplication}/>}
                    <DatePickersContainer>
                        <div>
                            <Controller
                                name="date_from"
                                control={control}
                                rules={{
                                    validate: value => dateValidator(value, 'date_from')
                                }}
                                render={({ field: { onChange, value, ref } }) => (
                                    <DatePicker
                                        ref={ref}
                                        selected={value}
                                        onChange={(date, event) => {
                                            onChange(date, event);
                                        }}
                                        errorWithoutText={!!serverErrors.overlaps}
                                        errorText={errors.date_from?.message || serverErrors.overlaps}
                                        label={t('timeOff.from')}
                                        required
                                    />
                                )}
                            />
                        </div>
                        <div>
                            <Controller
                                name="date_to"
                                control={control}
                                rules={{
                                    validate: value => dateValidator(value, 'date_to')
                                }}
                                render={({ field: { onChange, value, ref } }) => (
                                    <DatePicker
                                        ref={ref}
                                        selected={value}
                                        onChange={onChange}
                                        errorWithoutText={!!serverErrors.overlaps}
                                        errorText={errors.date_to?.message || serverErrors.overlaps}
                                        label={t('timeOff.to')}
                                        required
                                    />
                                )}
                            />
                        </div>
                    </DatePickersContainer>
                    {!!serverErrors.overlaps && <MainErrorBox type='error' text={serverErrors.overlaps}/>}
                    {watchFromDate && watchToDate && isEqual(watchFromDate as Date, watchToDate as Date) && <Controller
                        control={control}
                        name="requested_hours"
                        rules={{
                            required:  t('validations.is_required', {attribute: t('timeOff.requested_hours')}),
                            validate: value => +Number(value) <= 24 || t('timeOff.maximum_24_hours')
                        }}
                        render={({ field: { onChange, value, ref } }) => (
                            <NumericFormat
                                onValueChange={(values) => onChange(values.value)}
                                value={value}
                                inputRef={ref}
                                customInput={UniversalInput}
                                decimalSeparator="."
                                decimalScale={2}
                                valueIsNumericString
                                required
                                label={t('timeOff.requested_hours')}
                                errorText={errors.requested_hours?.message}
                                style={{width: 267}}
                            />
                        )}
                    />}
                    {
                        // {/*  not need this time */}
                        // watchFromDate && watchToDate && !isEqual(watchFromDate as Date, watchToDate as Date) && <Controller
                        // control={control}
                        // name="time_off_period"
                        // render={({ field }) => (
                        //     <RadioGroup
                        //         row
                        //         {...field}
                        //         onChange={(_, value) => {
                        //             field.onChange(value);
                        //             if (value === 'full') setIntervalWithHourData([]);
                        //         }}>
                        //         <FormControlLabel
                        //             value={'full'}
                        //             control={<RadioButton />}
                        //             label={t('timeOff.full_days')}
                        //         />
                                
                        //         <FormControlLabel
                        //             value={'partial'}
                        //             control={<RadioButton />}
                        //             label={'Partial Days'}
                        //         />
                        //     </RadioGroup>
                        // )}
                        // />
                    }

                    {/* dont need this time */}
                    {/* {watchTimeOffPeriod === 'partial' && watchFromDate && watchToDate && !isEqual(watchFromDate as Date, watchToDate as Date) && <PartialDateChoose
                        onChangeRange={handlePartialCalendarChangeRange}
                        onChangeCell={handlePartialCalendarChangeCell}
                        intervalWithHourData={intervalWithHourData}
                        startDate={watchFromDate as Date}
                        endDate={watchToDate as Date}
                    />} */}
                    <TimeOffBalance 
                        balance={balance} 
                        requestedDays={watchRequestedDays}
                        timeOffType={watchTimeOffType}
                    />
                    
                    <div>
                        <Controller
                            name="note"
                            rules={{ maxLength: { value: 2500, message: t('max_character', {attribute: 2500})} }}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextArea
                                    onChange={(event: ChangeEvent<HTMLTextAreaElement>) => { onChange(event.target.value) }}
                                    maxRows={5}
                                    value={value}
                                    label={t('timeOff.note')}
                                    placeholder={t('timeOff.comments')}
                                    maxLength={2500}
                                />
                            )}
                        />
                    </div>
                    {(role !== 'employee' && (role !== 'manager' || (role === 'manager' && currentUser.employee.id === employeeInfo.active_job_detail.manager?.id))) && <Controller
                        name="automatic_approval"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Checkbox
                                checked={value}
                                onChange={onChange}
                                label={t('timeOff.without_managers_approval')}
                            />
                        )}
                    />}
                </FieldsContainer>
            </DialogContainer>
        </DialogModal>
    )
};

const DialogContainer = styled.div `
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    height: calc(100vh - 300px);
    
    .type-error {
        margin-bottom: 0px;
        font-size: 11px;
        line-height: 18px;
        padding: 12px;
    }
`
const ToastContentContainer = styled.div`
    & > b {
        font-family: 'Aspira Demi', 'FiraGO Regular';
    }
`;

const FieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1;
`;

const DatePickersContainer = styled.div`
    display: flex; 
    flex-direction: row; 
    gap: 20px;
    & > div {
        flex: 1;
    }
`;