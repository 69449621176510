import React, { Fragment, useEffect, useState } from 'react';
import styled from "styled-components";
import { useToasts } from "react-toast-notifications";
import { useQueryClient } from 'react-query';
import { useHistory, Link } from 'react-router-dom';
import Text from 'components/Text';
import { currentUserSelector } from "redux/selectors";
import { setCurrentUser, setDomain } from 'redux/authSlice';
import { useDispatch, useSelector } from "react-redux";
import { getOwner, getUserRoleList, getPotentialOwners, changeOwner, getCurrentUser, createLocation, getLocations } from "services";
import useQuery from "hooks/useQueryCustom";
import EmployeeCard from "components/Employee/Card";
import DialogModal from 'components/Modal/Dialog';
import { Controller, useForm } from "react-hook-form";
import SelectDropdown from "../../../components/Dropdowns/SelectDropdown";
import Box from "@mui/material/Box";
import SelectWithLocationAdd from 'components/Dropdowns/SelectWithLocationAdd';
import UniversalInput from 'components/Input/UniversalInput';
import useMutationCustom from 'hooks/useMutationCustom';
import { useTranslation } from "react-i18next";
export const CompanyInfo = () => {
    const { t } = useTranslation();
    const { handleSubmit, setValue, control, setError, clearErrors, formState: { errors } } = useForm({
        shouldFocusError: true,
        defaultValues: {
            employee: null,
            role: null
        } as any
    });

    const companyAddressFormMethods = useForm({
        defaultValues: {
            location: null
        }
    });

    const companyNameFormMethods = useForm({
        defaultValues: {
            name: ''
        }
    });

    const dispatch = useDispatch();
    const currentUser = useSelector(currentUserSelector);
    const [owner, setOwner] = useState<any>();
    const [changeOwnerOpen, setChangeOwnerOpen] = useState<boolean>(false);
    const [companyAddressModal, setCompanyAddressModal] = useState<{ type: string, open: boolean }>({ type: '', open: false });
    const [loadingRequest, setLoadingRequest] = useState<boolean>(false);
    const [companyNameModal, setCompanyNameModal] = useState<boolean>(false);
    const { addToast } = useToasts();
    const history = useHistory();
    const queryClient = useQueryClient();

    const { data } = useQuery<any>(["subscription_info"], {
        endpoint: 'billing/subscription',
        options: { method: "get" },
    }, { enabled: true });

    const { data: companyLocation } = useQuery<any>(["get_company_location"], {
        endpoint: 'company_setting',
        options: { method: "get" },
    }, { enabled: true });

    const { mutate, isLoading } = useMutationCustom<string[], {}, {}>(["post_company_address"], {
        endpoint: 'company_setting/location', options: { method: "post" },
    }, {
        onSuccess: () => {
            setCompanyAddressModal({ type: '', open: false });
            addToast(`${t('settings.companyInfo.your_company_address_has_been')} ${companyAddressModal.type === 'Change' ? t('settings.companyInfo.changed') : t('settings.companyInfo.added')}`, {
                appearance: 'success', autoDismiss: true
            });
            queryClient.invalidateQueries('get_company_location');
            companyAddressFormMethods.reset();
        },
    });

    const { mutate: updateCompanyName, isLoading: companyNameChangeLoading } = useMutationCustom<string[], any, any>(["post_company_name"], {
        endpoint: 'company', options: { method: "post" },
    }, {
        onSuccess: () => {
            setCompanyNameModal(false);
            addToast(t('settings.companyInfo.company_name_changed'), {
                appearance: 'success', autoDismiss: true
            });
            getCurrentUser().then(res => {
                dispatch(setDomain(res.data.company.id));
                dispatch(setCurrentUser(res.data));
            });
        },
        onError: (err) => {
            err.errors.forEach((item: any) => {
                addToast(item.message, {
                    appearance: 'error',
                    autoDismiss: true,
                    placement: 'top-center'
                });
            });
        }
    });

    useEffect(() => {
        getOwner().then(res => setOwner(res.data))
    }, []);

    useEffect(() => {
        if (changeOwnerOpen) {
            setValue('employee', null)
            setValue('role', null)
            clearErrors()
        }
    }, [changeOwnerOpen]);

    const onSubmit = (data: any) => {
        setLoadingRequest(true)

        changeOwner(data).then(res => {
            getCurrentUser().then(res => {
                dispatch(setCurrentUser(res.data));
            });
            setLoadingRequest(false)
            setChangeOwnerOpen(false)
            getOwner().then(res => setOwner(res.data))
            history.push('/people')
            addToast(t('settings.companyInfo.company_owner_changed'), { appearance: 'success', autoDismiss: true })
        }).catch(err => {
            setLoadingRequest(false)
            err.response.data.errors.forEach((item: any) => {
                if (item.field && item.field === 'base') {
                    setError(item.field, { type: 'string', message: item.message })
                } else {
                    addToast(item.message, { appearance: 'error', autoDismiss: true });
                }
            });
        });
    };

    const onSubmitCompanyAddress = (data: any) => {
        let locationId = data.location?.id;

        mutate({ location_id: locationId });
    };

    const onSubmitCompanyName = (data: any) => {
        updateCompanyName(data)
    };

    const onError = (err: any) => {
        if (err) {
            addToast(<ToastContentContainer dangerouslySetInnerHTML={{ __html: t('globaly.fix_Highlighted')}}/>, {
                appearance: 'error',
                autoDismiss: true,
                placement: 'top-center'
            });
        }
    };

    return (
        <Fragment>
            <div style={{ display: 'flex', alignContent: 'center' }}>
                <div style={{ width: 3, height: 25, backgroundColor: '#339966', float: 'left', marginRight: 15 }} />
                <Text type="title">{t('settings.companyInfo.company_info')}</Text>
            </div>
            <Wrapper>
                <div style={{ display: 'flex', height: '100%' }}>
                    <BlockWrapper>
                        <TitleWrapper>
                            <p>{t('settings.companyInfo.company_name')}</p>
                            <span>{currentUser?.company?.name}</span>
                            <ActionTextTitle onClick={() => setCompanyNameModal(true)}>{t('settings.companyInfo.change_company_name')}</ActionTextTitle>
                        </TitleWrapper>

                        <TitleWrapper>
                            <p>{t('settings.companyInfo.account_owner')}</p>
                            {owner && <EmployeeCard employee={owner} key={owner?.id} fontSize={13}
                                additionalInfo={<ChangeOwnerWrapper
                                    onClick={() => setChangeOwnerOpen(true)}>{t('globaly.change')}</ChangeOwnerWrapper>} />}
                        </TitleWrapper>
                    </BlockWrapper>

                    <BlockWrapper>
                        <TitleWrapper>
                            <p>{t('settings.companyInfo.company_id')}</p>
                            <span>{currentUser?.company?.id}</span>
                        </TitleWrapper>

                        <TitleWrapper>
                            <p>{t('settings.companyInfo.used_seats_available_seats')}</p>
                            <span style={{ color: 'var(--dark-gray)', fontWeight: 'bold' }}>
                                <Link to="/list/filters?status=none">
                                    {data?.employee_count} {t('settings.companyInfo.employees')}
                                </Link> / { data?.plan === 'basic' && data?.count+' ' }
                                { data?.plan === 'core' ? t('settings.companyInfo.unlimited') : t('settings.companyInfo.seats') }
                            </span>
                        </TitleWrapper>
                    </BlockWrapper>
                    <BlockWrapper>
                        <TitleWrapper>
                            <p>{t('settings.companyInfo.company_address')}</p>
                            {companyLocation ?
                                <CompanyAddressContainer>
                                    <p>{`${companyLocation.location.address} ${companyLocation.location.address_details}`}</p>
                                    <p>{`${companyLocation.location.city}, ${companyLocation.location.state ?
                                        companyLocation.location.state.name : companyLocation.location.region} ${companyLocation.location.postal_code}`}</p>
                                    <ActionTextTitle onClick={() => setCompanyAddressModal({ type: 'Change', open: true })}>{t('settings.companyInfo.change_company_address')}</ActionTextTitle>
                                </CompanyAddressContainer> :
                                <ActionTextTitle onClick={() => setCompanyAddressModal({ type: 'Add', open: true })}>{t('settings.companyInfo.add_company_address')}</ActionTextTitle>}
                        </TitleWrapper>
                    </BlockWrapper>
                </div>
            </Wrapper>

            {changeOwnerOpen && <DialogModal
                open={changeOwnerOpen}
                title={t('settings.companyInfo.change_account_owner')}
                onClose={() => setChangeOwnerOpen(false)}
                actionButton={handleSubmit(onSubmit, onError)}
                withButtons
                cancelButtonText={t('globaly.cancel')}
                actionButtonText={t('globaly.submit')}
                actionLoading={loadingRequest}
                upperPosition
            >
                <ChangeWrapper>
                    <div style={{ textAlign: 'center' }}>
                        <HeaderText>{t('settings.companyInfo.change_account_owner')}</HeaderText>
                        <AddText>{t('settings.companyInfo.account_owner_access')}</AddText>
                    </div>

                    <InputWrapper>
                        <label>{t('settings.companyInfo.new_account_owner')}<sup>*</sup></label>
                        <Controller
                            name="employee"
                            control={control}
                            rules={{ required: t('validations.new_account_owner_required') }}
                            render={({ field: { onChange, value } }) => (
                                <SelectDropdown
                                    inputPlaceholder={t('settings.companyInfo.select_new_account_owner')}
                                    onChange={(_event: React.SyntheticEvent<Element, Event>, newValue: any) => {
                                        onChange(newValue)
                                    }}
                                    value={value}
                                    loadRemoteData={() => getPotentialOwners()}
                                    withPic
                                    errorText={errors.role ? errors.role.message : ''}
                                />
                            )}
                        />
                        <div style={{ marginTop: 3 }}>{t('settings.companyInfo.management_access')}</div>
                    </InputWrapper>

                    <InputWrapper>
                        <label>{t('settings.companyInfo.your_user_role')}<sup>*</sup></label>
                        <Controller
                            name="role"
                            control={control}
                            rules={{ required: t('validations.your_new_user_role_required') }}
                            render={({ field: { onChange, value } }) => (
                                <SelectDropdown
                                    inputPlaceholder={t('settings.companyInfo.select_user_role')}
                                    renderOption={(props: any, option: any) => (
                                        <Box component="li" {...props}
                                            style={{
                                                paddingLeft: 13,
                                                borderTop: option.addLine ? '1px solid #D6D6D6' : 'none'
                                            }}
                                            key={option.id ?? props.key}
                                        >
                                            {`${option.name} (${option.user_count})`}
                                        </Box>
                                    )}
                                    onChange={(_event: React.SyntheticEvent<Element, Event>, newValue: any) => {
                                        onChange(newValue)
                                    }}
                                    value={value}
                                    loadRemoteData={() => getUserRoleList(50, 1, 0, true, t)}
                                    errorText={errors.role ? errors.role.message : ''}
                                />
                            )}
                        />
                    </InputWrapper>
                </ChangeWrapper>
            </DialogModal>}

            <DialogModal
                open={companyAddressModal.open}
                onClose={() => { setCompanyAddressModal({ type: '', open: false }); companyAddressFormMethods.reset(); }}
                actionButton={companyAddressFormMethods.handleSubmit(onSubmitCompanyAddress)}
                actionLoading={isLoading}
                title={`${companyAddressModal.type} ${t('settings.companyInfo.company_address')}`}
                withButtons
                actionButtonText={t('globaly.save')}
                cancelButtonText={t('globaly.cancel')}
                maxWidth={'sm'}
                fullWidth
                upperPosition
            >
                <CompanyModalContainer>
                    <p>{t('settings.companyInfo.please_click_save')}</p>
                    <label>{t('settings.companyInfo.company_address')}<sup>*</sup></label>
                    <Controller
                        name="location"
                        control={companyAddressFormMethods.control}
                        rules={{ required: t('validations.company_address_required') }}
                        render={({ field: { value, onChange, ref } }) => (
                            <SelectWithLocationAdd
                                name='location'
                                inputPlaceholder={t('createPerson.select_location')} ///c
                                inputValue={value}
                                loadRemoteData={() => getLocations(100, 1, false, false)}
                                createRequest={createLocation}
                                onChange={onChange}
                                errorText={companyAddressFormMethods.formState?.errors.location?.message}
                                ref={ref}
                            />
                        )}
                    />
                </CompanyModalContainer>
            </DialogModal>

            <DialogModal
                open={companyNameModal}
                onClose={() => { setCompanyNameModal(false); companyNameFormMethods.reset(); }}
                actionButton={companyNameFormMethods.handleSubmit(onSubmitCompanyName)}
                actionLoading={companyNameChangeLoading}
                title={t('settings.companyInfo.change_company_name')}
                withButtons
                actionButtonText={t('globaly.change')}
                cancelButtonText={t('globaly.cancel')}
                maxWidth={'sm'}
                fullWidth
                upperPosition
            >
                <CompanyModalContainer>
                    <p>{t('settings.companyInfo.please_click_change')}</p>
                    <label>{t('settings.companyInfo.company_name')}<sup>*</sup></label>
                    <UniversalInput
                        inputProps={{ maxLength: 250 }}
                        errorText={companyNameFormMethods.formState.errors?.name?.message}
                        {...companyNameFormMethods.register('name', { required: t('validations.company_name_required'), maxLength: 250 })}
                    />
                </CompanyModalContainer>
            </DialogModal>
        </Fragment>
    )
};

const ToastContentContainer = styled.div`
    & > b {
        font-family: 'Aspira Demi', 'FiraGO Regular';
    }
`;

const Wrapper = styled.div`
     margin-top: 20px; 
     flex: 1; 
     background-color: #FFF; 
     padding: 18px 15px; 
     border-radius: 6px;
`;

const BlockWrapper = styled.div`
    flex: 0.23;
    
    > div {
        margin-bottom: 35px;
        margin-top: 13px;
        margin-left: 13px;
    }
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    > p {
        margin-bottom: 5px;
        font-family: 'Aspira Demi', 'FiraGO Regular';
    };
    
    span {
        color: #414141;
        & > a {
            color: var(--green);
            cursor: pointer;
            font-family: 'Aspira Demi', 'FiraGO Regular';
        }
    };
`;

const ChangeOwnerWrapper = styled.div`
    cursor: pointer;
    text-decoration: underline;
    font-weight: normal;    
        
    :hover {
        color: var(--orange);
    }
`;

const ChangeWrapper = styled.div`
    padding: 20px 30px;
    color: #676767;
    width: 500px;
    height: 450px;
`;

const HeaderText = styled.div`
    font-size: 18px;
    margin-bottom: 16px;
    font-weight: bold;
`;

const AddText = styled.div`
    color: #676767;
    font-size: 11px;
    margin-bottom: 45px;
    line-height: 1.5;
`;

const InputWrapper = styled.div`
    margin-bottom: 20px;
    
    > label {
        display: inline-block;
        margin-bottom: 6px;
        margin-top: 6px;
        & > sup {
            color: #C54343;
        }
    }
`;

const ActionTextTitle = styled.span`
    font-size: 12px;
    color: var(--dark-gray);
    text-decoration: underline;
    cursor: pointer;        
    &:hover {
        color: var(--orange);
    }
`;

const CompanyModalContainer = styled.div`
    padding-block: 20px;
    & > p {
        margin-bottom: 20px;
        font-size: 13px;
    };
    & > label {
        display: inline-block;
        margin-bottom: 6px;
        & > sup {
            color: #C54343;
        };
    };
`;

const CompanyAddressContainer = styled.div`
    & > span {
        color: #414141;
    };
`;